<!-- eslint-disable vue/no-v-html -->
<template>
	<w-flex scroll-y>
		<w-layout v-if="serviceObject !== null" column fill-height>
			<v-toolbar color="primary" dark flat height="78px">
				<w-layout align-center row>
					<w-flex v-if="backClosure !== null" shrink>
						<w-btn class="mr-4" color="white" flat icon="arrow_back" mini @click="backClosure" />
					</w-flex>
					<w-flex grow>
						<w-layout column>
							<v-toolbar-title v-text="name" />
							<p class="mb-0 caption">
								<span class="font-weight-medium">{{ $t('service-requests.w-navigation.service.reference') }} </span>{{ unique_reference }}
							</p>
						</w-layout>
					</w-flex>
					<w-flex shrink>
						<w-btn color flat icon="close" mini @click="close()">{{ $t('actions.close') }}</w-btn>
					</w-flex>
				</w-layout>
			</v-toolbar>
			<w-flex class="px-4 py-4">
				<w-layout column fill-height>
					<w-flex scroll-y>
						<p v-if="category !== null" class="mb-3">
							<span class="font-weight-bold">{{ $t('service-requests.w-navigation.service.category') }} :</span> <br />
							<v-chip class="ml-0" text-color="white" :color="category.color">{{ category.name }}</v-chip>
						</p>
						<div class="ql-bubble mb-4">
							<div class="ql-editor px-0 py-0" v-html="description"></div>
						</div>
						<p v-if="deliverable !== null" class="">
							<span class="font-weight-bold">{{ $t('service-requests.w-navigation.service.deliverable') }} :</span>
							{{ deliverable }}
						</p>
						<p v-if="price !== null" class="">
							<span class="font-weight-bold">{{ $t('service-requests.w-navigation.service.price') }} :</span>
							{{ price }}
						</p>
					</w-flex>
					<w-flex v-if="backClosure === null" style="flex-grow: 0">
						<w-btn v-if="!isSelected" class="ml-0" icon="add" small @click.stop="addService">
							{{ $t('actions.select') }}
						</w-btn>
						<w-btn v-if="isSelected" class="ml-0" icon="remove" :disabled="catalogServiceIds.includes(serviceId)" small @click.stop="removeService">
							{{ $t('actions.unselect') }}
						</w-btn>
					</w-flex>
				</w-layout>
			</w-flex>
		</w-layout>
	</w-flex>
</template>
<script>
import 'quill/dist/quill.bubble.css'
import ServiceRequestsModuleGuard from '@/mixins/ModulesGuards/ServiceRequests/ServiceRequestsModuleGuard'
export default {
	name: 'CatalogServiceDetail',
	mixins: [ServiceRequestsModuleGuard],
	props: {
		backClosure: {
			required: false,
			type: Function,
			default: null
		},
		servicesSelected: {
			required: true,
			type: Array
		},
		serviceId: {
			required: false,
			type: Number,
			default: undefined
		},
		filters: {
			default: () => ({
				categories: []
			}),
			required: true,
			type: Object
		},
		catalogOfferIds: {
			required: true,
			type: Array
		},
		catalogServiceIds: {
			required: true,
			type: Array
		}
	},
	data: function () {
		return {
			serviceObject: null
		}
	},
	computed: {
		name: function () {
			return typeof this.serviceObject?.name === 'undefined' ? 'Service inconnue' : this.serviceObject.name
		},
		unique_reference: function () {
			return typeof this.serviceObject?.unique_reference === 'undefined' ? '-' : this.serviceObject.unique_reference
		},
		category: function () {
			return {
				color: typeof this.serviceObject?.category?.color === 'undefined' ? '#000000' : this.serviceObject.category.color,
				name: typeof this.serviceObject?.category?.name === 'undefined' ? null : this.serviceObject.category.name
			}
		},
		deliverable: function () {
			return typeof this.serviceObject?.deliverable?.name === 'undefined' ? null : this.serviceObject.deliverable.name
		},
		description: function () {
			return typeof this.serviceObject?.commercial_description === 'undefined' ? '' : this.serviceObject.commercial_description
		},
		price: function () {
			return typeof this.serviceObject?.price === 'undefined'
				? null
				: new Intl.NumberFormat('fr-FR', {
						style: 'currency',
						currency: 'EUR',
						minimumFractionDigits: 2
				  })
						.format(this.serviceObject.price)
						.toString()
		},
		isSelected () {
			return this.servicesSelected.includes(this.serviceObject.id)
		}
	},
	watch: {
		serviceId: {
			handler: function (newVal, oldVal) {
				if (typeof newVal === 'undefined') {
					this.close()
					return
				}
				if (newVal === oldVal) {
					return
				}
				this.findService()
			},
			immediate: true
		}
	},
	methods: {
		close: function () {
			this.appService.goTo(
				(() => {
					let r = { name: 'service-requests-catalog', query: {} }
					if (this.filters.categories.length >= 1) {
						r.query['categories'] = this.filters.categories.map(c => c.toString(16)).join(',')
					}
					if (this.catalogServiceIds.length >= 1) {
						r.query['catalog-service'] = this.catalogServiceIds.map(c => c.toString(16)).join(',')
					}
					if (this.catalogOfferIds.length >= 1) {
						r.query['catalog-offer'] = this.catalogOfferIds.map(c => c.toString(16)).join(',')
					}
					return r
				})()
			)
		},
		findService: async function () {
			try {
				this.$emit('loading')
				this.serviceObject = await this.service.findCatalogService(this.accountingFirmId, this.serviceId).promise
				this.$emit('loaded')
			} catch (err) {
				this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('non_existing_resource'))
				this.appService.goTo('service-requests-catalog')
			}
		},
		addService: function () {
			this.$emit('loading')
			this.eventBus.emit(this.events.SERVICE_SELECT, this.serviceObject.id)
			setTimeout(() => {
				this.$emit('loaded')
				this.close()
			}, 500)
		},
		removeService: function () {
			this.$emit('loading')
			this.eventBus.emit(this.events.SERVICE_UNSELECT, this.serviceObject.id)
			setTimeout(() => {
				this.$emit('loaded')
				this.close()
			}, 500)
		}
	}
}
</script>
